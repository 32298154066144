import { ApiService } from './api.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, DestroyRef, inject } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Role } from '../models/role.enum';
import { UserSession } from '../models/user-session.model';
import { CookieService } from './cookie.service';
import { Subject } from 'rxjs';
import { Login } from '../models/login.model';
import { Router } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from '../../shared/adapter/UnsubscribeOnDestroyAdapter';


@Injectable({
  providedIn: 'root'
})

export class AuthService extends UnsubscribeOnDestroyAdapter {
  error$: Subject<string> = new Subject<string>(); // Subject para emitir mensajes de error


  // private currentUserSubject: BehaviorSubject<User>;
  // public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    public apiService: ApiService) {
      super();

  //   this.currentUserSubject = new BehaviorSubject<User>(
  //     JSON.parse(localStorage.getItem('currentUser') || '{}')
  //   );

  //   this.currentUser = this.currentUserSubject.asObservable();
  }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }


  login(username: string, password: string): void {
    this.subs.sink= this.apiService.login(username, password)
    .subscribe({
      next: (res) => {
        if (res) {
            const loginData = new Login(res.id, res.name, res.token);
            localStorage.setItem('currentUser', JSON.stringify({ id: loginData.id, name: loginData.name }));
            this.cookieService.createCookie("token", loginData.token, 3600);
            this.router.navigate(['/inicio']);
        } else {
          this.error$.next('Error en la autenticación');
        }
      },
      error: (error) => {
        this.error$.next('DNI o contraseña incorrectos');
        console.log('prueba')
      },
    });

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // this.currentUserSubject.next(this.currentUserValue);
    return of({ success: false });
  }

}
